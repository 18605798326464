<template>
  <div class="pageContol UnitEdit">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">单位管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{unitJson.stu != 'edit'? '新增':'修改'}}企业单位</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="8rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="单位名称" prop="UnitName">
                  <el-input v-model="ruleForm.UnitName" size="small" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="上级单位" prop="parentCompId">
                 <el-select
                    size="small"
                    v-model="ruleForm.parentCompId"
                    remote
                    :remote-method="getCompSourceList"
                    filterable
                    clearable
                    placeholder="请至少输入两个字搜索"
                  >
                    <el-option
                      v-for="item in compSourceList"
                      :key="item.compId"
                      :label="item.compName"
                      :value="item.compId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="单位类型" prop="company">
                  <el-select v-model="ruleForm.company" placeholder="请选择" size="small" @change="companyTypeChange">
                    <el-option
                      v-for="item in companyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="社会信用代码" prop="creditCode">
                  <el-input v-model="ruleForm.creditCode" size="small"></el-input>
                </el-form-item>
                <el-form-item label="所属行政区划" prop="areaId">
                  <el-cascader
                   clearable
                    filterable
                    v-model="ruleForm.areaId"
                    :options="areatreeList"
                    :props="propsarea"
                    size="small"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="所属行业" prop="Industry">
                  <el-select
                    v-model="ruleForm.Industry"
                    clearable
                    filterable
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      size="small"
                      v-for="item in IndustryList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="企业联系人" prop="compUser">
                  <el-input v-model="ruleForm.compUser" size="small"></el-input>
                </el-form-item>
                 <el-form-item label="联系人电话" prop="contactphone">
                  <el-input v-model="ruleForm.contactphone" size="small"></el-input>
                </el-form-item>
                <el-form-item label="法人姓名" prop="compLegal">
                  <el-input v-model="ruleForm.compLegal" size="small"></el-input>
                </el-form-item>
                <el-form-item label="法人身份证号" prop="compIdcard">
                  <el-input v-model="ruleForm.compIdcard" size="small"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="compEmail">
                  <el-input v-model="ruleForm.compEmail" size="small"></el-input>
                </el-form-item>
                <el-form-item label="经营范围" prop="compBusiness">
                  <el-input v-model="ruleForm.compBusiness" size="small"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="compAddress">
                  <el-input v-model="ruleForm.compAddress" size="small"></el-input>
                </el-form-item>
                <!-- 屏蔽旧版 市场负责人 --
                <el-form-item label="市场负责人" prop="marketLeader">
                  <el-select
                    v-model="ruleForm.marketLeader"
                    clearable
                    filterable
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      size="small"
                      v-for="item in options"
                      :key="item.adminId"
                      :label="item.fullname"
                      :value="item.adminId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                -->
                <el-form-item label="市场负责人" prop="marketId">
                  <el-select
                      v-model="ruleForm.marketId"
                      clearable
                      filterable
                      placeholder="请选择"
                      size="small"
                  >
                    <el-option
                        size="small"
                        v-for="item in marketArray"
                        :key="item.saleId"
                        :label="item.saleName"
                        :value="item.saleId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="营业执照" prop="thumbnail">
                  <!-- <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                  <p style="color:#f46173">请上传PNG、JPG格式的图片，且文件大小不超过2MB！</p> -->
                  <el-upload
          class="upload-demo upload-btn upload-btndrag"
          :action="actionUrl"
          :on-change="handleAvatarSuccess2"
          :show-file-list="false"
          :auto-upload="false"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div v-if="ruleForm.thumbnail" style="margin-top: 10px; margin-left: 10px">
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span style="color: #409eff;margin-top:10px">
              {{ ruleForm.fileName?ruleForm.fileName:'未命名文件' }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a
                @click="lookExl(ruleForm.thumbnail, ruleForm.compLicenseFileType)"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                style="color: #409eff; margin: 0 15px"
                >预览</a
              >
              <!-- <a @click="reomveExl()" style="color: red; margin: 0 15px"
                >删除</a
              > -->
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
                </el-form-item>

                <el-form-item label="公司类型" prop="hrCompType">
                  <el-select v-model="ruleForm.hrCompType" placeholder="请选择" size="small">
                    <el-option
                        v-for="item in hrCompTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="公司规模" prop="hrCompScale">
                  <el-select v-model="ruleForm.hrCompScale" placeholder="请选择" size="small">
                    <el-option
                        v-for="item in hrCompScaleList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="公司简介" prop="hrAbout">
                  <el-input v-model="ruleForm.hrAbout" size="small" type="textarea" maxlength="1000" :autosize="{ minRows: 4, maxRows: 8}" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="认证状态" prop="authState" v-if="unitJson.stu == 'edit'">
                  <el-select
                      v-model="ruleForm.authState"
                      placeholder="请选择"
                      size="small"
                      clearable
                      disabled
                  >
                    <el-option
                        v-for="item in authStateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>


              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
var creditCode = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入社会信用代码"));
  } else if (
      value &&
      !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
      )
  ) {
    callback(new Error("请输入正确的社会信用代码"));
  } else {
    callback();
  }
};
export default {
  name: "UnitEdit",
  components: {},
  mixins: [UploadPic],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入联系人电话"));
      } else {
        callback();
      }
    };
    var validateEMail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (value == "" || value == undefined || value == null) {
        callback();
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确的邮箱地址"));
        } else {
          callback();
        }
      }
    };
    return {
      previewLoding1:false,
      fileType:'',
      ImgSrc:'',
      stu: "add",
      // 基本信息
      ruleForm: {
        UnitName: "",
        company: "",
        creditCode: "",
        areaId: "",
        Industry: "",
        contactphone: "",
        compLegal: "",
        compIdcard: "",
        compEmail: "",
        compBusiness: "",
        compAddress: "",
        marketLeader:'', //市场负责人
        thumbnail: "",
        fileKey: "",
        parentCompId:'',
        marketId: '',    //销售人员
        compUser: '', // 企业联系人
        fileName:'',

        hrCompType:'',
        hrCompScale:'',
        hrAbout:'',
        authState:'',
      },
      authStateList:[],
      hrCompTypeList: [],
      hrCompScaleList: [],
      rules: {
        UnitName: [
          { required: true, trigger: "blur", message: "请输入企业名称" }
        ],
        company: [
          { required: true, trigger: "change", message: "请选择企业类型" }
        ],
        creditCode: [
          { required: true, trigger: "blur", validator: creditCode }
        ],
        areaId: [
          { required: true, trigger: "change", message: "请选择所属行业区划" }
        ],
        Industry: [
          { required: true, trigger: "change", message: "请选择所属行业" }
        ],
        contactphone: [{ required: true, trigger: "blur", validator: Phone }],
        compEmail: [
          { required: false, validator: validateEMail, trigger: "blur" }
        ],
        compIdcard: [
          { required: false, validator: this.$validateIdCards, trigger: "blur" }
        ],
        marketId: [
          { required: true,  message: "请选择市场负责人", trigger: "change" }
        ]
      },
      IndustryList: [], // 行业
      companyList: [],
      unitJson: {},
      areatreeList: [],
      compSourceList: [],
      marketLeaderArr: [], //市场负责人下拉框数组对象
      options:[],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      marketArray: [], //销售人员可选列表
    };
  },
  created() {
    this.unitJson = this.$route.query;
    this.getmarketList();
    this.getIndustryList();
    this.getHrCode();
    this.getAuthStateList();
    if(this.unitJson.stu == "addsubordinate") {
      this.ruleForm.parentCompId = this.unitJson.compId,
      this.getCompany(this.ruleForm.parentCompId)
      
    }
    console.log(this.marketArray);
    if (this.unitJson.stu == "edit") {
      this.queryData(this.unitJson.compId);
     
    }
  },
  mounted() {
    this.getareatree();
  },
  computed: {},
  methods: {
    // 认证状态 - 下拉数据
    getAuthStateList() {
      // console.log(this.$setDictionary("HR_COMP_AUTH", "list"))
      const authStateList = this.$setDictionary("HR_COMP_AUTH", "list");
      let authStatelist = [{ value: "", label: "全部" }];
      for (const key in authStateList) {
        authStatelist.push({
          value: key,
          label: authStateList[key],
        });
      }
      this.authStateList = authStatelist;
    },
    getHrCode() {
      const hrCompTypeList = this.$setDictionary("HR_COMP_TYPE", "list");
      const hrCompScaleList = this.$setDictionary("HR_COMP_SCALE", "list");
      let hrCompTypelist = [];
      for (const key in hrCompTypeList) {
        hrCompTypelist.push({
          value: key,
          label: hrCompTypeList[key]
        });
      }
      this.hrCompTypeList = hrCompTypelist;

      let hrCompScalelist = [];
      for (const key in hrCompScaleList) {
        hrCompScalelist.push({
          value: key,
          label: hrCompScaleList[key]
        });
      }
      this.hrCompScaleList = hrCompScalelist;
    },
    companyTypeChange(){
      if (this.ruleForm.company == '50'){
        this.rules.creditCode = null;
        this.rules.Industry = null;
      } else {
        this.rules.creditCode = [
          { required: true, trigger: "blur", validator: creditCode }
        ];
        this.rules.Industry = [
          { required: true, trigger: "change", message: "请选择所属行业" }
        ];
      }
    },
    //销售人员列表
    getmarketList() {
      this.$post("/biz/user/sale/selectList", {
        saleType: "10",
      }).then((ret) => {
        if (ret.status == "0") {
          console.log(ret.data);
          this.marketArray = ret.data;
        }
      });
    },
    // 市场负责人列表
    getmarketManagerList() {
      this.$post("/sys/admin/market/manager").then(res => {
        if (res.status == "0") {
          this.options = res.data || [];
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        setTimeout(() => {
          this.loading = false;
          this.options = this.marketLeaderArr.filter(item => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = this.marketLeaderArr;
      }
    },
    //
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getIndustryList() {
      const IndustryList = this.$setDictionary("INDUSTRY", "list");
      const companyList = this.$setDictionary("COMPANYTYPE", "list");
      let list = [];
      let companylist = [];
      for (const key in IndustryList) {
        list.push({
          value: key,
          label: IndustryList[key]
        });
      }
      for (const key in companyList) {
        companylist.push({
          value: key,
          label: companyList[key]
        });
      }
      this.IndustryList = list;
      this.companyList = companylist;
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      this.ruleForm.compLicenseFileType = extension;
       let size = res.size / 1024 / 1024;
        //   this.fileType =
        // (extension != "pdf" && extension != "PDF" ) ? "IMAGE" : "PDF";
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
     
       let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
        this.$Postformat("/sys/upload", formData)
          .then(result => {
              this.ruleForm.fileName = res.name;
            this.ruleForm.thumbnail = result.data.fileURL;
            this.ruleForm.fileKey = result.data.fileKey;
            // console.log(result);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
    },
    // // 编辑保存
    doAddSave() {
      const params = {
        compName: this.ruleForm.UnitName,
        compType: this.ruleForm.company,
        compNo: this.ruleForm.creditCode,
        areaId: this.ruleForm.areaId,
        compIndustry: this.ruleForm.Industry,
        compPhone: this.ruleForm.contactphone,
      };
      
      if (this.ruleForm.compUser) {
        params.compUser = this.ruleForm.compUser;
      }
      if (this.ruleForm.compLegal) {
        params.compLegal = this.ruleForm.compLegal;
      }
      if (this.ruleForm.parentCompId) {
        params.parentCompId = this.ruleForm.parentCompId;
      }
      if (this.ruleForm.compIdcard) {
        params.compIdcard = this.ruleForm.compIdcard;
      }
      if (this.ruleForm.compEmail) {
        params.compEmail = this.ruleForm.compEmail;
      }
      if (this.ruleForm.compBusiness) {
        params.compBusiness = this.ruleForm.compBusiness;
      }
      if (this.ruleForm.compAddress) {
        params.compAddress = this.ruleForm.compAddress;
      }
      if (this.ruleForm.fileKey) {
        params.compLicense = this.ruleForm.fileKey;
      }
      if (this.ruleForm.fileName) {
        params.compLicenseName = this.ruleForm.fileName;
      }
      if (this.ruleForm.marketLeader) {
        params.adminId = this.ruleForm.marketLeader;
      }
      if (this.unitJson.stu == "edit") {
        if (this.unitJson.compId) {
          params.compId = this.unitJson.compId;
        }
      }
      if (this.ruleForm.marketId) {
        params.marketId = this.ruleForm.marketId;
      }

      if (this.ruleForm.hrCompType) {
        params.hrCompType = this.ruleForm.hrCompType;
      }
      if (this.ruleForm.hrCompScale) {
        params.hrCompScale = this.ruleForm.hrCompScale;
      }
      if (this.ruleForm.hrAbout) {
        params.hrAbout = this.ruleForm.hrAbout;
      }
      if (this.ruleForm.authState) {
        params.authState = this.ruleForm.authState;
      }



      const el = this.$refs["ruleForm"];
      if (this.ruleForm.compIdcard == "") {
        el.clearValidate("compIdcard");
      }
      el.validate(valid => {
        if (valid) {
          this.$post(
            this.unitJson.stu == "add" ||  this.unitJson.stu == "addsubordinate"
              ? "/sys/company/insert"
              : "/sys/company/modify",
            params
          )
            .then(res => {
              ;
              if (res.status == 0) {
                this.$message({
                  message: this.unitJson.stu == "add" ? "新增成功" : "修改成功",
                  type: "success"
                });
                // setTimeout(() => {
                this.doCancel();
                // }, 3000);
              }
            })
            .catch(err => {
              return;
            });
        }
      });
    },
    // 上级单位
    getCompSourceList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.compSourceList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.compSourceList = [];
      }
    },
     /**
     * 上级单位回显
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then(res => {
        // ;
        this.compSourceList = [res.data];
      });
    },
    // 编辑查询
    queryData(compId) {
      this.$post("/sys/company/info", { compId })
        .then(res => {
          const data = res.data || {};
          this.ruleForm = {
            UnitName: data.compName,
            company: data.compType,
            creditCode: data.compNo,
            areaId: data.areaId,
            Industry: data.compIndustry,
            contactphone: data.compPhone,
            compLegal: data.compLegal,
            compIdcard: data.compIdcard,
            compEmail: data.compEmail,
            compBusiness: data.compBusiness,
            compAddress: data.compAddress,
            thumbnail: data.compLicense,
            fileKey: data.compLicenseKey,
            parentCompId:data.parentCompId,
            compId:data.compId,
            marketLeader:data.adminId || '',
            marketId: data.marketId,
            compUser: data.compUser,
            fileName:data.compLicenseName || '',
            compLicenseFileType:data.compLicenseFileType || '',

            hrCompScale:data.hrCompScale || '',
            hrCompType:data.hrCompType || '',
            hrAbout:data.hrAbout || '',
            authState:data.authState || ''
          };
          this.companyTypeChange();
          this.getCompany(data.parentCompId ? data.parentCompId : data.compId);
        })
        .catch(err => {
          return;
        });
    },
     lookExl(compLicense,compLicenseFileType) {
      this.previewLoding1 = true;
      this.fileType = compLicenseFileType;
      if (compLicenseFileType == "pdf" || compLicenseFileType == "PDF") {
        setTimeout(() => {
          pdf.embed(compLicense, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = compLicense;
      }
    },
    /* 取消 */
    doCancel() {
      this.$router.push({
        path: "/web/UnitList",
        query: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="less">
.UnitEdit {
  .el-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form-item {
      width: 100%;
      margin-bottom: 22px;
    }
  }
   .el-upload {
  height: 180px !important;
}
.upload-btn {
  height: 180px;
}
}

</style>

